.modalGiftCard .modal-content {
  background: #E3E3E3;
}
.modalGiftCard header {
  margin-bottom: 0;
  background: #E3E3E3;
}
.modalGiftCard .modal-section {
  border: 0;
  overflow: hidden;
}
.modalGiftCard img {
  width: 100%;
}
#modalGiftCardsInfo .modal-header {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}